<!-- progress card -->
<div class="w-fit-content h-100" (mouseenter)="showTooltip = isHoverPopupEnabled" (mouseleave)="showTooltip = false"
    #progressCard>
    <div class="card-wrapper d-grid gap-2 h-100">
        <div *ngIf="data?.assignedBy && data?.assignedBy !== EVENT && data?.assignedBy !== userId" class="top-info-con align-items-center d-flex gap-1">
            <div class="profile-placeholder item-center rounded-circle" skeletonNew [isLoading]="isLoading">
                <span class="h6 white">{{data.assignedBy === ASSIGNED_TYPE.LMS ? ASSIGNED_TYPE.AUTO_ASSIGNED : data?.assigneeInitials}}</span>
            </div>
            <div class="label gray" skeletonNew [isLoading]="isLoading" *ngIf="data?.assignedBy !== ASSIGNED_TYPE.LMS; else elseBlock">
                <span><strong>{{data?.assigneeName}}</strong></span> assigned you this as part of
                <span><strong>upcoming project requirements</strong></span>
            </div>
            <ng-template #elseBlock>
              <span class="label gray"><strong>Auto assigned to you from LMS</strong></span>
            </ng-template>
        </div>

        <div class="card-con d-flex flex-column align-items-start position-relative">
            <div class="top-con item-center"
                [ngClass]="{
                    'course-card': data?.type === TYPE.COURSE,
                    'assessment-card': data?.type === TYPE.ASSIGNMENT,
                    'certification-card': data?.type === TYPE.CERTIFICATION
                }" skeletonNew [isLoading]="isLoading">
                <label class="h5 nowrap m-2">{{data?.title}}</label>
            </div>

            <div *ngIf="data?.type && cardConfig.labelRequired"
                class="tag-con d-inline-flex justify-content-center align-items-center position-absolute m-1 border-radius-4 label black">
                {{data?.type}}
            </div>

            <div *ngIf="data.assignedBy === userId && !cardConfig.labelRequired"
                class="tag-con d-inline-flex justify-content-center align-items-center position-absolute m-1 border-radius-4 label black">
                Added by you
            </div>

            <div *ngIf="data?.userStatus !== STATUS.NOT_STARTED && data?.progress"
                class="progress-con d-inline-flex align-items-center mt-n1">
                <div class="progress-bar" [ngStyle]="{
                        'background': calculateBackgroundColor(data),
                        'width': data?.progress < 100 ? data?.progress+'%' : '100%'
                    }">
                </div>
            </div>

            <div class="buttom-con d-flex flex-column align-items-start gap-2 align-self-stretch">
              <div class="d-flex gap-2">
                <div *ngIf="data?.userStatus" class="status-con item-center p-1" skeletonNew [isLoading]="isLoading" [skeletonWidth]="'30%'"
                  [ngClass]="{
                      'not-started': data?.userStatus === STATUS.NOT_STARTED && !cardConfig.showCurrentState,
                      'in-Progress': data?.userStatus === STATUS.IN_PROGRESS && !cardConfig.showCurrentState,
                      'completed': data?.userStatus === STATUS.COMPLETED && !cardConfig.showCurrentState,
                      'active': data.currentState === STATUS.ACTIVE && cardConfig.showCurrentState,
                      'expired': data.currentState === STATUS.EXPIRED && cardConfig.showCurrentState
                      }">
                  <label class="h6">{{cardConfig.showCurrentState ? data.currentState : userStatusMap[data?.userStatus]}}</label>
                </div>
                <div class="status-con item-center p-1"
                    [ngClass]="{
                        'result-status-passed': data?.resultStatus === STATUS.PASSED,
                        'result-status-failed': data?.resultStatus === STATUS.FAILED
                        }">
                    <label class="h6">{{userStatusMap[data?.resultStatus]}}</label>
                </div>
              </div>
              <div class="caption h-16px gray gap-1" skeletonNew [isLoading]="isLoading" [skeletonWidth]="'60%'">
                  <span *ngIf="data?.source">on {{data?.source}}</span>
                  <span *ngIf="data?.originCreator">
                    <div class="gray-dot"></div>
                    <span>by {{data?.originCreator}}</span>
                  </span>
                  <span *ngIf="data?.duration">
                    <div class="gray-dot"></div>
                    <span>{{data?.durationInTime}}</span>
                  </span>
              </div>
            </div>

            <div *ngIf="data?.isFinished"
                class="accelerator-con d-flex align-items-center justify-content-center gap-1">
                <mat-icon class="white fs-20">run_circle</mat-icon>
                <label class="label white">Accelerator</label>
            </div>

            <!-- on progress card hover-->
            <div class="hover-card-wrapper d-flex align-items-center" *ngIf="showTooltip && !isLoading"
            [ngClass]="getBestTooltipPosition()" @delayTooltip>
            <div class="item-center">
              <div class="polygon-icon-con">
                <app-svg-icon fill="var(--color-white)" src="icons/polygon.svg"></app-svg-icon>
              </div>
              <div>
                  <div *ngIf="data?.isFinished"
                      class="accelerator-hover-con d-flex align-items-center justify-content-center">
                      <mat-icon class="white fs-20 pr-1">run_circle</mat-icon>
                      <label class="label white">
                          Accelerator: Finish this for swift progression through the learning path—skip the rest!
                      </label>
                  </div>

                  <div class="hover-card d-flex flex-column align-items-start px-20 pb-20 pt-3"
                      [ngClass]="{'hover-card-top-radius':data?.isFinished}">

                      <label class="h4 black">{{data?.title}}</label>
                      <div class="d-flex flex-column align-items-start align-self-stretch gap-2">
                          <div class="d-flex justify-content-between align-self-stretch align-items-end">
                              <div
                                  class="tag-con d-inline-flex justify-content-center align-items-center border-radius-4 label black">
                                  {{data?.type}}
                              </div>
                              <ng-container *ngIf="data?.progress; else percentage">
                                  <div class="status-con item-center p-1"
                                      [ngClass]="{
                                          'not-started': data?.userStatus === STATUS.NOT_STARTED,
                                          'in-Progress': data?.userStatus === STATUS.IN_PROGRESS,
                                          'completed': data?.userStatus === STATUS.COMPLETED,
                                          'result-status-passed': data.userStatus === STATUS.PASSED,
                                          'result-status-failed': data.userStatus === STATUS.FAILED
                                      }">
                                      <label class="h6">{{userStatusMap[data?.userStatus]}}</label>
                                  </div>

                              </ng-container>

                              <ng-template #percentage>
                                  <label class="label"
                                      [ngClass]="{
                                          'not-started-txt': data?.userStatus === STATUS.NOT_STARTED,
                                          'in-Progress-txt': data?.userStatus === STATUS.IN_PROGRESS || statusFailedAndCompleted,
                                          'completed-txt': data?.userStatus == STATUS.COMPLETED
                                      }">
                                      {{ (data?.progress) | number: '1.0-0'}}% complete
                                  </label>
                              </ng-template>
                          </div>

                          <div *ngIf="data?.userStatus !== STATUS.NOT_STARTED && data?.progress"
                              class="progress-con d-inline-flex align-items-center mt-n1">
                              <div class="progress-bar" [ngStyle]="{
                                  'background': calculateBackgroundColor(data),
                                  'width':data?.progress < 100 ? data?.progress+'%' : '100%'
                              }">
                              </div>
                          </div>
                      </div>

                      <div class="caption gray gap-1">
                          <span *ngIf="data?.source">on {{data?.source}}</span>
                          <div *ngIf="data?.originCreator" class="gray-dot"></div>
                          <span *ngIf="data?.originCreator">by {{data?.originCreator}}</span>
                          <div *ngIf="data?.duration" class="gray-dot"></div>
                          <span *ngIf="data?.duration">{{data?.duration}}h</span>
                      </div>

                      <label class="align-self-stretch caption description">{{data?.description}}</label>

                      <div class="w-100">
                        <span *ngIf="data.startDate || data.endDate">
                          <mat-divider></mat-divider>
                          <div class="d-flex w-100 py-3">
                            <span *ngIf="data.startDate">Issued {{data.startDate | date: 'd MMM yyyy'}} </span>
                            <span *ngIf="data.endDate" class="ml-auto">Expires {{data.endDate | date: 'd MMM yyyy'}}</span>
                          </div>
                        </span>
                        <span *ngIf="cardConfig.showSkills && data.skillItemIds?.length">
                          <mat-divider></mat-divider>
                          <div class="d-flex w-100 py-3 gap-2 flex-wrap">
                            <span class="skill-tag px-2 py-1" *ngFor="let skillItemId of data.skillItemIds"> {{data?.skillItemIdVsName?.[skillItemId]}} </span>
                          </div>
                          <mat-divider></mat-divider>
                        </span>
                      </div>

                      <div class="buttom-info-con d-flex align-items-center align-self-stretch gap-2 p-2 border-radius-4" *ngIf="data?.specialization">
                          <div class="route-icon-con item-center rounded-circle">
                              <mat-icon class="route-icon fs-20 white">route</mat-icon>
                          </div>
                          <label class="caption gray">Assigned to you as part of {{data?.specialization}} skill’s learning
                              path</label>
                      </div>

                      <div class="d-flex gap-3 w-100">
                          <!-- <button mat-button *ngIf="canDrop" class="secondary-button-warn h6"
                          (click)="onDropClick()">DROP</button> -->
                          <button mat-button *ngIf="cardConfig.showEditButton && data.assignedBy === userId" (click)="onEditClick()" class="secondary-button h6">
                            EDIT
                          </button>
                          <button mat-button (click)="viewDetails(data)" *ngIf="data?.contentUrl || data?.referenceFile || data.referenceUrl"
                              class="item-center primary-button border-0 gap-1 w-100 flex-1 cursor-pointer">
                              <label class="h6 white cursor-pointer">View details</label>
                              <mat-icon class="new-tab-icon fs-18 white cursor-pointer">open_in_new</mat-icon>
                          </button>
                      </div>
                  </div>
              </div>
            </div>
            </div>
        </div>
    </div>

    
</div>
