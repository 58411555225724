export const API_ENDPOINT = {
    MY_SKILLS_V2: 'sms/v2/getMySkills',
    GET_APPROVALS: 'sms/v2/getApprovals',
    BROWSE_SKILLS_DATA: 'sms/v2/getBrowseSkillsData',
    SAVE_MY_SKILLS: 'sms/saveMySkills',
    USER_INTERVIEW_FEEDBACK: 'sms/getUserInterviewFeedback',
    ENDORSEMENT_TEAM_SKILL_APPROVALS_V2: 'sms/v2/getTeamSkillApprovals',
    SAVE_TEAM_SKILL_APPROVALS: 'sms/v2/saveTeamSkillApprovals',
    USER_DETAILS: 'sms/user-details/',
    GET_AVAILABLE_USERS_FOR_DELEGATION: 'sms/getAvailableUsersForDelegation',
    MY_SPECIALIZATIONS: 'sms/getMySpecializations',
    MY_SPECIALIZATIONS_V2: 'sms/v2/getMySpecializations',
    GET_RECOMMENDED_SPECIALIZATIONS: 'sms/v2/getRecommendedSpecializationsForUser',
    VALIDATE_DYNAMIC_RESUME_TEMPLATE: 'admin/validateDynamicResumeBuilderTemplate',
    GET_ALL_RESUME_BUILDER_TEMPLATES: 'sms/getAllResumeBuilderTemplates',
    DOWNLOAD_DYNAMIC_RESUMES_BASED_ON_USERIDS: 'sms/downloadDynamicResumesBasedOnUserIds',
    GENERATE_TEMPLATE_FOR_LEARNING_PATH: 'admin/downloadTemplateForLearningPathCareerPrism',
    GENERATE_TEMPLATE_FOR_LEARNING_ELEMENT: 'admin/downloadTemplateForLearningElementCareerPrism',
    GET_SPECIALIZATION_CONFIG: 'sms-reports/getSpecConfig',
    GET_DASHBOARDS: 'sms-reports/getDashboards',
    GET_SMS_SETTINGS: 'admin/getSMSSettings',
    DOWNLOAD_DYNAMIC_RESUME_OF_SINGLE_USER: 'sms/downloadDynamicResumeOfSingleUser',
    GET_RESUMES_BUILT_FROM_PROFILE: 'sms/getResumesBuiltFromProfile',
    REQUEST_NEW_SKILL: 'sms/requestNewSkill',
    REQUEST_NEW_SKILLS_V2: 'sms/v2/raiseNewSkillsRequest',
    GET_ACM_DATA: 'admin/v2/getACMData',
    GLOBAL_ADMIN_GET_CLIENTS: 'globalAdmin/getClients',
    GET_ACM_REPORT_HISTORY: 'admin/v2/getACMReportHistory',
    GET_SAVED_ACM_FILTERS: 'admin/v2/getSavedACMFilters',
    SAVE_ACM_FILTERS: 'admin/v2/saveACMFilters',
    DELETE_ACM_FILTERS: 'admin/v2/deleteACMFilters',
    BULK_SAVE_MY_PROFILE: 'sms/bulkSaveMyProfile',
    GET_ACM_FILTERS: 'admin/v2/getACMFilters',
    ACM_USERS_DETAILS: 'sms/skillClusterMapping/userDetails',
    GENERATE_ACM_DOWNLOAD_CSV: 'admin/v2/generateACMReport',
    GET_ACM_FILTER_DATA: 'admin/v2/getClusterMappingFilterData',
    GET_HOME: 'sms/v2/getHome',
    SAVE_IRRELEVANT_SKILLS: 'sms/saveIrrelevantSkills',
    GET_USER_SKILLS_RECOMMENDATION: 'sms/v2/getUserSkillRecommendations',
    DELETE_SKILLS: (endPoint) => `sms/v2/${endPoint}`,
    ADD_SKILL_LE_PATH: (clientId: string) => `admin/career-prism-api/learningPath/${clientId}/`,
    UPDATE_LE_PATH: (clientId: string, primaryKey: string) => `admin/career-prism-api/learningPath/${clientId}/${primaryKey}/`,
    LE_PATH_CSV_DOWNLOAD: (clientId: string) => `admin/career-prism-api/learningPath/${clientId}/downloadableS3URI`,
    SEARCH_LE_PATH: (clientId: string) => `admin/career-prism-api/learningPath/${clientId}/search/`,
    CREATE_SOURCE_MASTER: (clientId: string) => `admin/career-prism-api/learningSource/${clientId}/`,
    UPDATE_SOURCE_MASTER: (clientId: string, primaryKey: string) => `admin/career-prism-api/learningSource/${clientId}/${primaryKey}/`,
    SEARCH_SOURCE_MASTER: (clientId: string) => `admin/career-prism-api/learningSource/${clientId}/search/`,
    LEARNING_ELEMENT_DOWNLOAD_CSV: (clientId) => `admin/career-prism-api/learningElement/${clientId}/downloadableS3URI`,
    CREATE_LEARNING_ELEMENT: (clientId: string, userId: string) => `admin/career-prism-api/learningElement/${clientId}/user/${userId}/`,
    UPDATE_LEARNING_ELEMENT: (clientId: string, primaryKey: string, userId: string) => `admin/career-prism-api/learningElement/${clientId}/${primaryKey}/user/${userId}/`,
    LEARNING_ELEMENT_FIELDS: (clientId: string) => `admin/career-prism-api/learningElement/${clientId}/fields/`,
    LEARNING_ELEMENT_CONSTANTS: (clientId: string) => `sms/career-prism-api/userLearningElement/${clientId}/fields`,
    SEARCH_LE_MASTER: (clientId: string) => `admin/career-prism-api/learningElement/${clientId}/search/`,
    SEARCH_SMS_LE_MASTER: (clientId: string) => `sms/career-prism-api/learningElementSearchRouter/${clientId}/search/`,
    SEARCH_SKILL_FOR_LE_PATH: (clientId: string) => `admin/career-prism-api/skill/${clientId}/search/`,
    GET_IN_PROGRESS_ELEMENTS: (clientId: string, userId: string) => `sms/career-prism-api/userLearningElement/${clientId}/${userId}/search/`,
    GET_SPECIALIZATION_LIST: (clientId: string, userId: string) => `sms/career-prism-api/specialization/${clientId}/list/${userId}`,
    GET_SKILL_GAP_ANALYSIS: (clientId: string, userId: string, specializationId) => `sms/career-prism-api/specialization/${clientId}/analysis/${userId}/${specializationId}`,
    GET_LEARNING_PROGRESS_PATH: (clientId: string, userId: string, learningPathId: string, level: number) => `sms/career-prism-api/specialization/${clientId}/path/${userId}/${learningPathId}/${level}`,
    GET_LE_FOR_INDIVIDUAL: (clientId: string, userId: string) => `sms/career-prism-api/specialization/${clientId}/individual/${userId}`,
    SAVE_LE_INDIVIDUAL: (clientId: string) => `sms/career-prism-api/userLearningElement/${clientId}/individual/`,
    GET_SKILLS: 'admin/getSkills',
    GET_SKILLS_FROM_RESUME: 'sms/v2/getSkillsFromResume',
    GET_MY_SKILLS: 'sms/getMySkills',
    VALIDATE_MY_SPECIALIZATION: 'sms/validateMySpecialization',
    SAVE_MY_SPECIALIZATION: 'sms/saveMySpecializations',
    GET_REJECTED_SPECIALIZATION: 'sms/getRejectedSpecializations',
    GET_ROLES: 'admin/getRoles',
    PUBLISH_DASHBOARDS: 'admin/publishDashboards',
    GET_EXTERNAL_BI_DASHBOARDS:'sms-reports/getExternalBiDashboards',
    GET_EXTERNAL_BI_EMBEDDED_SESSION_URL:'sms-reports/getExternalBiEmbeddedSessionUrl',
    GET_TAG_VALUES_FOR_TAG_ADMIN: 'admin/getTagValuesForTag',
    GET_DESIGNATION_LEVELS:'admin/getDesignationLevels',
    GET_UUID_FROM_EMPID:'internal/sms-admin/getUUIDFromEmpId',
    GET_DESIGNATIONS:'admin/getDesignations',
    GET_RESUME_DOWNLOAD_DETAILS: 'sms/getDynamicResumeDownloads',
    GET_ONBOARDING_L1_SKILLS: 'sms/v2/getRecommendedL1Skills',
    GET_ONBOARDING_L2_SKILLS: 'sms/v2/getRecommendedL2Skills',
    UPDATE_SKILLS_RECO_FEEDBACK: 'sms/v2/updateSkillsRecommendationFeedback',
    GET_SKILL_OVERLAY_DETAILS: 'sms/v2/getSkillOverlayDetails',
    SAVE_INTEGRATIONS: 'admin/saveIntegrations',
    GET_MARKET_INTELLIGENCE: 'admin/getMarketIntelligence',
    GET_ADMIN_SKILL_CLUSTERS: 'admin/getSkillClusters',
    INIT_SKILLS_RECOMMENDATION: 'sms/v2/initSkillsRecommendation',
    EMIT_FEEDBACK_EVENT: 'sms/v2/updateSkillsRecommendationFeedback',
    GET_DATA_FOR_CONFIRMATION_BUILDER: 'sms/getDataForConfirmationBuilder',
    GET_DYNAMIC_RESUME_DOWNLOAD: 'sms/downloadDynamicResumeBasedOnConfirmationUiData',
    DELETE_RESUME_FROM_USER_PROFILE: 'sms/deleteResumeFromUserProfile',
    GET_IJP_FILTERS: 'internal-job-posting/filters',
    APPROVE_SOCIAL_ENDORSEMENT: 'sms/approveSocialEndorsement',
    REQUEST_SOCIAL_ENDORSEMENT: 'sms/requestSocialEndorsement',
    CANCEL_SOCIAL_ENDORSEMENT: 'sms/cancelSocialEndorsement',
    UNENDORSE_SOCIAL_ENDORSEMENT: 'sms/unendorseSocialEndorsement',
    GIVE_OR_APPROVE_SOCIAL_ENDORSEMENT: 'sms/giveOrApproveSocialEndorsement',
    GET_REQUESTED_SOCIAL_ENDORSEMENT: 'sms/getRequestedSocialEndorsement',
    GET_RECOMMENDATIONS_FOR_ENDORSEMENT: 'sms/getRecommendationsForEndorsement',
    GET_MY_REQUESTED_SOCIAL_ENDORSEMENT: 'sms/getMyRequestedSocialEndorsement',
    GET_SOCIAL_ENDORSEMENT_DATA_FOR_SKILL: 'sms/getSocialEndorsementDataForSkill',
    USER_ACCESS_ROLE_UPLOAD_TEMPLATE: 'admin/downloadTemplateForUserAccessRole',
    SAVE_USER_PROFILE: 'admin/saveUserProfile',
    SAVE_MY_PROFILE: 'sms/saveMyProfile',
    SAVE_MY_PROJECT: 'sms/saveMyProjects',
    DELETE_MANUALLY_ADDED_DATA: 'sms/deleteManuallyAddedUserData',
    GET_USER_SKILLS_WITH_TYPE_AND_NAME:'sms/getUserSkillsWithTypeAndName',
    GET_SPEC_FOR_SKILL_CLUSTER_AND_USER_ID: 'sms/getSpecialisationForSKillClusterAndUserId',
    GET_SKILL_CLUSTERS_FOR_ASSIGNER: 'sms/getSkillClustersForAssigner',
    GET_MY_COURSES: 'sms/getMyCourses',
    GET_MY_CERTIFICATIONS: 'sms/getMyCertifications',
    FETCH_COPILOT_CREDENTIALS: 'sms/v2/fetchCopilotCredentials',
    GET_USER_DATA_FROM_RESUME: 'sms/getUserDataFromResume',
    GET_USER_INFO: 'sms/v2/getUserInfo',
    
    ASSIGN_USER_LEARNING_ELEMENT: (clientId: string) => `sms/career-prism-api/userLearningElement/${clientId}/assign/individual`,
    UPDATE_USER_LEARNING_ELEMENT: (clientId: string) => `sms/career-prism-api/userLearningElement/${clientId}/update/individual`,
    GET_FILE_USING_REFERENCE_PATH: (clientId: string) => `sms/career-prism-api/uploads/${clientId}/download`,
    UPLOAD_FILE: (clientId: string) => `sms/career-prism-api/uploads/${clientId}`,
    REQUEST_LEARNING_ELEMENT: (clientId: string) => `sms/career-prism-api/userLearningElement/${clientId}/requestNewLearningElement`,

    // IDP
    CREATE_GROWTH_PLAN: (clientId: string) => `admin/idp-api/globalDevelopmentPlan/${clientId}/create`,
    SEARCH_USER_GROUPS: (clientId: string) => `admin/idp-api/userGroup/${clientId}/search/`,
    DELETE_USER_GROUP: (clientId: string, userGroupId: string) => `admin/idp-api/userGroup/${clientId}/${userGroupId}/`,
    GET_EXCLUDE_EMPLOYEE_USER_GROUPS: (clientId: string) => `admin/idp-api/userGroup/${clientId}/details/`,
    GET_MY_GROWTH_PLAN: (clientId: string) => `admin/idp-api/globalDevelopmentPlan/${clientId}/search`,
    UPDATE_MY_GROWTH_PLAN: (clientId: string) => `admin/idp-api/globalDevelopmentPlan/${clientId}/update`,
    GET_MY_GROWTH_PLAN_EDIT_INFO: (clientId: string) => `admin/idp-api/globalDevelopmentPlan/${clientId}/search`,
    GET_ADMIN_GOALS: (clientId: string) => `admin/idp-api/globalGoal/${clientId}/search/global`,
    ADD_NEW_GOAL_ADMIN: (clientId: string) => `admin/idp-api/globalGoal/${clientId}/user`,
    UPDATE_GOAL_ADMIN: (clientId: string) => `admin/idp-api/globalGoal/${clientId}/update`,
    SEARCH_SKILLS: (clientId: string) => `admin/idp-api/allSkill/${clientId}/search/?search=`,
    UPDATE_GLOBAL_GOAL_ADMIN: (clientId: string) => `admin/idp-api/globalGoal/${clientId}/global/update`,
    SEARCH_SKILLS_MY_GOAL: (clientId: string) =>`sms/idp-api/mySkill/${clientId}/search/?search=`,


    GET_GOAL_DATA_LIST_IDP: 'admin/goalDataListForIDPUserGoal',
    GET_EXTRA_DETAILS_IDP: 'admin/goalEnrichmentForIDPUserGoal',
    GENERATE_TEMPLATE: 'admin/downloadTemplateForIDPUserGoal',

    ADD_NEW_GOAL_SMS: (clientId: string) => `sms/idp-api/myGoal/${clientId}/user`,
    SEARCH_SKILLS_SMS: (clientId: string) => `sms/idp-api/mySkill/${clientId}/search/?search=`,
    GET_GOALS_MYGOALS: (clientId: string) => `sms/idp-api/myGoal/${clientId}/search`,
    UPDATE_GOALS_MYGOALS: (clientId: string) => `sms/idp-api/myGoal/${clientId}/update`,
    GET_GOALS_OVERVIEW: (clientId: string) => `sms/idp-api/myGoal/${clientId}/user/overview`,
    UPDATE_RECOMMENDED_GOALS: (clientId: string) => `sms/idp-api/myGoal/${clientId}/recommended`,
    GET_GROWTH_PLANS_MYGOALS: (clientId: string) => `sms/idp-api/myDevelopmentPlan/${clientId}/user`,
    CHECK_IN_MYGOAL: (clientId: string) => `sms/idp-api/myGoal/${clientId}/user/checkin`,
    GET_EXTRA_DETAILS_MYGOAL: 'sms/goalEnrichmentForMyGoal',
    GET_GOAL_DATA_LIST_MY_GOALS: 'sms/goalDataListForIDPMyGoal',

    GET_GOALS_TEAM_GOALS: (clientId: string) => `sms/idp-api/teamGoal/${clientId}/user/search`,
    REVIEW_GOALS_TEAM_GOALS: (clientId: string) => `sms/idp-api/teamGoal/${clientId}/review`,
    GET_GOALS_OVERVIEW_TEAM_GOALS: (clientId: string, userId: string) => `sms/idp-api/teamGoal/${clientId}/teamGoal/overview/${userId}`,
    GET_GROWTH_PLANS_TEAM_GOALS: (clientId: string) => `sms/idp-api/teamDevelopmentPlan/${clientId}/user`,
    GET_GOAL_OWNERS: 'sms/v2/getReporteesData',
    SAVE_PROJECT_RESPONSIBILITY: 'sms/updateMyProjectResponsibilities',
    GET_TAG_VALUES_FOR_TAG_SMS :'sms/getTagValuesForTagSms',
    SAVE_STAFFING_ROLE : 'sms/saveStaffingRole',
    SAVE_REQUESTED_STAFFING_ROLE: 'sms/saveRequestedStaffingRole',
    ADVANCED_PRISM_SEARCH: 'sms/getAutoCompleteForGlobalSearch',
    ADVANCED_PRISM_SEARCH_FILTERS: 'sms/getSearchFiltersWithAllowedOperators',
    ADVANCED_PRISM_GLOBAL_SEARCH: 'sms/getDataForAdvancedSearch',
    NORMAL_PRISM_GLOBAL_SEARCH: 'sms/getDataForGlobalSearch',

    GET_COMMENTS: (clientId: string, userId: string, page: number, limit: number) => `sms/idp-api/activity/${clientId}/${userId}/COMMENTS?page=${page | 1}&limit=${limit | 10}`,
    ADD_COMMENTS: (clientId: string, goalId: string) => `sms/idp-api/activity/comment/${clientId}/add/${goalId}/`,
    REPLY_COMMENTS: (clientId: string, userId: string) => `sms/idp-api/activity/comment/${clientId}/reply/add/${userId}/`,

    // Avatar
    GET_LIST_OF_AVATAR_ADMIN : 'admin/getAvatars',
    ENABLE_AVATAR_ADMIN : 'admin/enableAvatar',
    DISABLE_AVATAR_ADMIN : 'admin/disableAvatar',
    DELETE_AVATAR_ADMIN : 'admin/deleteAvatar',
    UPDATE_AVATAR_ADMIN : 'admin/updateAvatar',
    ADD_AVATAR_ADMIN : 'admin/saveAvatar',
    GET_LIST_OF_AVATAR_SMS : 'sms/getUserAvatars',
    UPDATE_AVATAR_SMS : 'sms/saveMyProfile',

    //APA
    GET_CLIENT_ADMIN_USER: 'admin/getClientAdminUser',
    GET_ALL_PROCESS_LOGS : 'admin/getAllProcessLogsForAclient',
    CREATE_A_PROCESS_LOG : 'admin/createAProcessLog',
    CREATE_SIGNED_URL :'admin/createSignedUrl',
    UPDATE_A_PROCESS_LOG_BY_ID :'admin/updateAprocessLogById',
    START_PROCESS_SCAN : 'admin/startProcessScan',
    START_PROCESS_GENERATE : 'admin/startProcessGenerate',
    GET_A_PROCESS_LOG_BY_ID :'admin/getAprocessLogById',
    GET_PROCESS_RESULT_SUMMARY: 'admin/getProcessResultSummary',

    SKILL_HISTORY: 'sms/getSkillHistory',

    GET_ADMIN_USER_PROFILE: 'admin/getUserProfile',
    GET_USER_PROFILE: 'sms/getMyProfile',
}
