<div class="add-goals-main-con idp">
  <div class="add-goals-header py-3 px-20 d-flex align-items-center justify-content-between">
    <span class="h4 white">{{title}}</span>
    <mat-icon (click)="onClose()" class="cursor-pointer white">close</mat-icon>
  </div>
  <div class="add-goal-content p-20">
    <form [formGroup]="planForm" class="mb-20">

    <div *ngIf="isTeamGoals" class="mt-0 mb-4">
      <label class="body fw-500 black">Goal Owner</label>
      <div class="label mt-1" *ngIf="editGoalObj || currentUser?.name">{{planForm.get('goalOwner')?.value?.[0]?.name}}</div>
      <div class="owner-btn mt-1" *ngIf="!editGoalObj && !currentUser?.name">
        <button class="secondary-button" mat-stroked-button *ngIf="!showGoalOwnerDropdown" (click)="showUserSelect()">
          <mat-icon class="btn-icon fs-16">add</mat-icon>
          SELECT USER
        </button>
      </div>

      <ng-select *ngIf="showGoalOwnerDropdown" [items]="usersList" [closeOnSelect]="false" [multiple]="true" [formControl]="planForm.get('goalOwner')">
        <ng-template ng-option-tmp let-item="item">
          <div class="d-flex align-items-center">
            <span class="initials d-flex align-items-center justify-content-center"
                  [style.background]="item.color">
              <span class="caption">{{util.getShortName(item.name)}}</span>
            </span>
            {{item.name}}
          </div>
        </ng-template>
        <ng-option *ngFor="let employee of usersList" [value]="employee">
          <div class="d-flex align-items-center p-1">
            <span class="initials d-flex align-items-center justify-content-center"
                  [style.background]="employee.color">
              <span class="caption">{{util.getShortName(employee.name)}}</span>
            </span>
            {{employee.name}}
          </div>
        </ng-option>
        <ng-template ng-label-tmp let-item="item">
          <div class="d-flex align-items-center p-1 selected-values gap-1">
            <span class="initials d-flex align-items-center justify-content-center"
                  [style.background]="item.color">
              <span class="caption">{{util.getShortName(item.name)}}</span>
            </span>
            {{item.name}}
            <mat-icon class="icon fs-16 cursor-pointer" (click)="onRemoveUserClick(item)">close</mat-icon>
          </div>
        </ng-template>
      </ng-select>
    </div>

      <ng-container  *ngIf="!(isMyGoals || isTeamGoals)">
        <hr class="mt-0 mb-4">
        <mat-radio-group aria-label="Select an option" class="grid row" [formControl]="planForm.get('category')"
          name="option">
          <mat-radio-button class="col-sm" [value]="1">Assign this goal</mat-radio-button>
          <mat-radio-button class="col-sm" [value]="2">Show as recommendations</mat-radio-button>
        </mat-radio-group>
        <hr class="mt-4 mb-20">

        <!-- USER GROUPS SECTION -->
        <div class="input-con-half mb-20">
          <label class="body fw-500 black">User Groups</label>
          <ng-select #ngSelect class="mt-1 min-w-150" [multiple]="true"
            [formControl]="planForm.get('userGroups')" [clearable]="false" [closeOnSelect]="false"
            (change)="syncCheckboxes($event)"
            [ngClass]="{'error-status': planForm.get('userGroups').touched && planForm.get('userGroups').hasError('required') }"
            (scrollToEnd)="onScrollToEnd()"
            [items]="userGroupData?.data?.userGroupDetails">

            <!-- Header template for "All users" -->
            <ng-template ng-header-tmp>
              <div class="user-group-option">
                <div class="clickable" (click)="toggleAllUsers($event)">
                  <div class="d-flex item-center">
                    <input type="checkbox" class="mr-2" [value]="allSelected" [checked]="allSelected">
                    All User Groups
                  </div>
                  <div class="caption gray fs-11">{{userGroupData?.data?.totalCount}} user groups</div>
                </div>
              </div>
              <div class="h6 mt-3 mb-2">
                USER GROUPS
              </div>
            </ng-template>

            <!-- Option template for each user group -->
            <ng-template ng-option-tmp let-item="item">
              <div class="user-group-option">
                <div class="option-audience-checkbox">
                  <div class="d-flex item-center">
                    <input type="checkbox" class="mr-2 clickable" [value]="item" [checked]="item.selected">
                    {{item.title}}
                  </div>
                  <div class="caption gray fs-11">{{item.numberOfEmployees}} users</div>
                </div>
                <button class="user-group-edit-button h6 primary" (click)="newUserGroup($event, item)">EDIT</button>
              </div>
            </ng-template>

            <!-- Footer template -->
            <ng-template ng-footer-tmp>
              <div class="caption fw-500 primary cursor-pointer" (click)="newUserGroup(); ngSelect.close()">
                CREATE NEW USER GROUP
              </div>
            </ng-template>

            <!-- ng-option for each user group -->
            <ng-template ng-label-tmp let-item="item">
              <div class="d-flex align-items-center p-1 selected-values gap-1">
                <span class="initials d-flex align-items-center justify-content-center"
                      [style.background]="item.color"
                      style="width: 32px; height: 32px; border-radius: 50%;">
                  <span class="caption">{{util.getShortName(item.title)}}</span>
                </span>
                {{item.title}}
                <mat-icon class="icon fs-16 cursor-pointer" (click)="onRemoveClick(item)">close</mat-icon>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </ng-container>

      <!-- ACCOMPLISHMETS SECTION -->
      <div class="mb-20">
        <label class="body fw-500 black">{{dialogLabel}}</label>
        <ng-select [formControl]="planForm.get('selectedAccomplishment')" bindValue="id" [clearable]="false" class="mt-1 min-w-150"
          [items]="accomplishments" (change)="onAccomplishmentChange($event)">

          <!-- Option template for each user group -->
          <ng-template ng-option-tmp let-item="item">
            <div class="accomplishment-group-option">
              <mat-icon class="mr-1">{{goalTypeIconsMap[item.type]}}</mat-icon>
              <div class="option-accomplishment">
                {{item.label}}
              </div>
            </div>
          </ng-template>

          <!-- ng-option for each user group -->
          <ng-template ng-label-tmp let-item="item">
            <div class="accomplishment-group-option">
              <mat-icon class="mr-1">{{goalTypeIconsMap[item.type]}}</mat-icon>
              <div class="option-accomplishment">
                {{item.label}}
              </div>
            </div>
          </ng-template>

        </ng-select>
      </div>

      <div *ngFor="let accomplishment of accomplishments" class="mb-20"
        [ngSwitch]="planForm.get('selectedAccomplishment')?.value">

        <ng-container *ngSwitchCase="accomplishment.id">
          <div *ngIf="accomplishment.id !== '2'">
            <button class="mb-20 secondary-button"
              *ngIf="!dynamicFormControls[accomplishment.id].showSelect && !dynamicFormControls[accomplishment.id].selectedItem"
              mat-stroked-button (click)="toggleSelect(accomplishment.id)">
              <mat-icon class="btn-icon fs-16">add</mat-icon>
              SELECT {{accomplishment.type | uppercase}}
            </button>
          </div>
          <div class="mb-3 d-flex">
            <mat-icon class="basic-info-icon fs-16 gray pr-1">info</mat-icon>
            <span class="label gray fw-300">{{ infoTips[accomplishment.id] }}</span>
          </div>

          <ng-container *ngIf="accomplishment.id !== '2' && dynamicFormControls[accomplishment.id]?.showSelect && !dynamicFormControls[accomplishment.id]?.selectedItem">
            <ng-select class="mb-20 min-w-150" #skillSelect
              [items]="dataForSelectedAccomplisment"
              [virtualScroll]="true"
              [clearable]="false"
              [loading]="loadingAccomplishments"
              [formControl]="planForm.get('enrichmentList')"
              bindLabel="title"
              bindValue="id"
              (change)="onItemSelect(accomplishment.id, $event)"
              (scrollToEnd)="onScrollToEndAccomplishments(accomplishment.id)"
              [typeahead]="searchText$"
              (search)="onSearch($event)"
              >
              <ng-option *ngFor="let item of dataForSelectedAccomplisment" [value]="item">
                {{ item.title }}
              </ng-option>
          </ng-select>
          </ng-container>

          <!-- Accomplishment type skill form -->
          <ng-container *ngIf="accomplishment.id === '1' && dynamicFormControls[accomplishment.id].selectedItem">
            <div class="selected-skill mb-20 border-radius-8 py-12 px-3">
              <div class="label d-flex justify-content-between align-items-center">
                <div class="text h5">{{dynamicFormControls[accomplishment.id]?.selectedItem?.title}}</div>
                <div class="skill-grp item-center">
                  <span class="text py-1 px-2 item-center border-radius-4">
                    Technical
                  </span>
                  <mat-icon class="selected-skill-close cursor-pointer mb-1 gray" (click)="deselect(accomplishment.id)">
                    close</mat-icon>
                </div>
              </div>
              <hr class="mt-2 mb-2 divider">
              <div class="body d-flex flex-column">
                <div class="d-flex flex-row justify-content-between">
                  <span class="label gray fw-400">Skill Group</span>
                  <span class="label black fw-500">{{subCategoryAccomplishment?.skill?.skillHierarchy?.[0]?.name}}</span>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <div class="label gray fw-400">Skill Sub-Group</div>
                  <div class="label black fw-500">{{subCategoryAccomplishment?.skill?.skillHierarchy?.[1]?.name}}</div>
                </div>
              </div>
              <hr class="mt-2 mb-2 divider">
              <div class="d-flex align-items-center gap-3">
                <!-- Current Rating -->
                <div class="grid-item">
                  <label class="mb-1 body fw-500">Current Rating</label>
                  <ng-select [clearable]="false" [formControl]="planForm.get('currentRating')" class="accomplish-input">
                    <ng-option *ngFor="let rating of ratings" [value]="rating.id">
                      {{ rating.id }}
                    </ng-option>
                  </ng-select>
                </div>
                <!-- Target Rating -->
                <div class="grid-item">
                  <label class="mb-1 body fw-500">Target Rating</label>
                  <ng-select
                    [clearable]="false"
                    [formControl]="planForm.get('targetRating')"
                    class="accomplish-input"
                    [ngClass]="{'is-invalid': planForm.get('targetRating')?.invalid && planForm.get('targetRating')?.touched}">
                    <ng-option *ngFor="let rating of ratings" [value]="rating.value">
                      {{ rating.value }}
                    </ng-option>
                 </ng-select>
                </div>
                <!-- Improvement Method -->
                <div class="grid-item">
                  <label class="mb-1 body fw-500">Improvement Method</label>
                  <ng-select
                    [clearable]="false"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [formControl]="planForm.get('improvementMethod')"
                    class="accomplish-input"
                    [ngClass]="{'is-invalid': planForm.get('improvementMethod')?.invalid && planForm.get('improvementMethod')?.touched}">
                    <ng-option *ngFor="let method of improvementMethods" [value]="method">
                      {{ method }}
                    </ng-option>
                  </ng-select>
                </div>

              </div>
            </div>
          </ng-container>

          <!-- Accomplishment type course form -->
          <ng-container *ngIf="accomplishment.id === '3' && dynamicFormControls[accomplishment.id].selectedItem">
            <div class="course-body enrichment-container mb-20">
              <div class="cross-section float-right">
                <mat-icon class="selected-skill-close cursor-pointer mb-1 gray" (click)="deselect(accomplishment.id)">
                  close
                </mat-icon>
              </div>
              <div class="progress-card-sec mb-20">
                <app-progress-card [data]="subCategoryAccomplishment?.course" [isHoverPopupEnabled]="false"></app-progress-card>
              </div>
            </div>
          </ng-container>

          <!-- Accomplishment type Cerification form -->
          <ng-container *ngIf="accomplishment.id === '4' && dynamicFormControls[accomplishment.id].selectedItem">
            <div class="certification-body enrichment-container mb-20">
              <div class="cross-section float-right">
                <mat-icon class="selected-skill-close cursor-pointer mb-1 gray" (click)="deselect(accomplishment.id)">
                  close
                </mat-icon>
              </div>
              <div class="progress-card-sec mb-20">
                <app-progress-card [data]="subCategoryAccomplishment?.certification" [isHoverPopupEnabled]="false"></app-progress-card>
              </div>
            </div>
          </ng-container>

          <!-- Accomplishment type specialization || role form -->
          <ng-container *ngIf="['5', '6'].includes(accomplishment.id) && dynamicFormControls[accomplishment.id].selectedItem">
            <div class="enrichment-container mb-20 d-flex flex-column">
              <div class="cross-section ml-auto">
                <mat-icon class="selected-skill-close cursor-pointer mb-1 gray" (click)="deselect(accomplishment.id)">
                  close
                </mat-icon>
              </div>
              <div class="table-group-container mb-20">
                <div class="header-section p-1 d-flex gap-2">
                  <div class="icon item-center">
                    <mat-icon class="selected-skill-close cursor-pointer mt-1 ml-1 gray fs-16">
                      {{ accomplishment.id === 5 ? 'school' : 'assignment_ind'}}
                    </mat-icon>
                  </div>
                  <div class="label-sec item-center">
                    <div class="h5">{{dynamicFormControls[accomplishment.id]?.selectedItem?.title}}</div>
                    <!-- <div class="label" *ngIf="accomplishment.id === 6">Primary</div> -->
                  </div>
                </div>
                <ng-container *ngIf="dataSourceMandatory?.data?.length && false"> <!-- for now hiding the table as per product requirement-->
                  <ng-container *ngTemplateOutlet="tableTemplate; context: {title: 'Mandatory', dataSource: dataSourceMandatory, displayedColumns: displayedColumns}"></ng-container>
                </ng-container>
                <ng-container *ngIf="dataSourceCondtionalMandatory?.data?.length && false">
                  <ng-container *ngTemplateOutlet="tableTemplate; context: {title: 'Conditional Mandatory', hasBadge: true, dataSource: dataSourceCondtionalMandatory, displayedColumns: displayedColumns}"></ng-container>
                </ng-container>
                <ng-container *ngIf="dataSourceOptional?.data?.length && false">
                  <ng-container *ngTemplateOutlet="tableTemplate; context: {title: 'Optional', dataSource: dataSourceOptional, displayedColumns: displayedColumns}"></ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <div class="mb-20" *ngIf="accomplishment.id === '2'">
            <div for="goalName" class="body fw-500 black">Goal Name</div>
            <textarea id="goalName" required
            [ngClass]="{'is-invalid': planForm.get('goalName')?.invalid && planForm.get('goalName')?.touched}"
            [formControl]="planForm.get('goalName')" placeholder="e.g: Q1 - 2024 Plan"
              class="goal-text-area border-radius-4 px-12 py-1 mt-1" maxlength="200"></textarea>
            <div *ngIf="planForm.get('goalName')?.hasError('maxlength')" class="error">
              Maximum 200 characters allowed
            </div>
          </div>

          <div class="mb-20">
            <span class="body fw-500 black">Description</span>
            <div class="mt-1">
              <textarea class="goals-desc-area border-radius-4 p-2" [formControl]="planForm.get('goalDescription')" maxlength="500"
                placeholder="Add context to the goal" autocomplete="off"></textarea>
            </div>
          </div>

          <div class="d-flex align-items-center gap-3">
            <div>
              <span class="body fw-500 black">Growth plan</span>
              <ng-select *ngIf="!planForm.get('developmentPlan')?.disabled" class="mt-1" dropdownPosition="top" [clearable]="planForm.get('developmentPlan').value" [formControl]="planForm.get('developmentPlan')" class="extra-inputs mt-1"
                [ngClass]="{'is-invalid': planForm.get('developmentPlan')?.invalid && planForm.get('developmentPlan')?.touched}">
                <ng-option *ngFor="let plan of developmentPlans" [value]="plan.developmentPlanId">
                  {{ plan.name }}
                </ng-option>
              </ng-select>
              <div *ngIf="planForm.get('developmentPlan')?.disabled" class="item-center border-radius-4 mt-1 no-growth-plan">
                No Growth plans
              </div>
            </div>
            <div *ngIf="planForm.get('developmentPlan').value">
              <div class="d-flex align-items-center gap-1">
                <span class="body fw-500 black">Due date</span>
                <img src="assets/icons/info.svg">
              </div>
              <div>
                <mat-form-field appearance="outline" class="extra-inputs">
                  <mat-icon matPrefix class="gray btn-icon fs-16 mt-10">date_range</mat-icon>
                  <input matInput (focus)="picker.open()" [matDatepicker]="picker"
                    [formControl]="planForm.get('dueDate')" [min]="minDate" [max]="activePlanEndDate">
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <!-- <div>
              <span class="body fw-500 black">Visibility</span>
              <ng-select dropdownPosition="top" [formControl]="planForm.get('visibility')" class="extra-inputs">
                <ng-option *ngFor="let visibility of visibilityOptions" [value]="visibility">
                  {{ visibility }}
                </ng-option>
              </ng-select>
            </div> -->
          </div>
          <div *ngIf="accomplishment.id === '2'">
            <div class="goals-divider my-20"></div>
            <span class="h5">How will you measure progress?</span>
            <div class="d-flex align-items-center gap-3 mt-12">
              <div class="d-flex flex-column">
                <span class="body fw-500 black">Measurement</span>
                <app-generic-multilevel-select
                  class="mt-1"
                  [ngClass]="{'is-invalid': planForm.get('measurement')?.invalid && planForm.get('measurement')?.touched}"
                  [options]="measurementOptions"
                  [formControl]="planForm.get('measurement')"
                  [dropdownPosition]="'top'"
                  [minWidth]="250"
                 ></app-generic-multilevel-select>
               </div>

              <div *ngIf="planForm.get('measurement')?.value?.toLowerCase() !== 'complete_incomplete'">
                <span class="body fw-500 black">Current value</span>
                <input type="text" required class="accomplish-input goal-text-area mt-1"
                [ngClass]="{'is-invalid': planForm.get('currentRating')?.invalid && planForm.get('currentRating')?.touched}"
                [formControl]="planForm.get('currentRating')">
              </div>
              <div *ngIf="planForm.get('measurement')?.value?.toLowerCase() === 'complete_incomplete'">
                <span class="body fw-500 black">Current value</span>
                <input type="text" [formControl]="planForm.get('measurement_currentRating')" class="accomplish-input goal-text-area mt-1">
              </div>

              <div *ngIf="planForm.get('measurement')?.value?.toLowerCase() !== 'complete_incomplete'">
                <span class="body fw-500 black">Target value</span>
                <input type="text" required class="accomplish-input goal-text-area mt-1"
                [ngClass]="{'is-invalid': planForm.get('targetRating')?.invalid && planForm.get('targetRating')?.touched}"
                [formControl]="planForm.get('targetRating')">
              </div>
              <div *ngIf="planForm.get('measurement')?.value?.toLowerCase() === 'complete_incomplete'">
                <span class="body fw-500 black">Target value</span>
                <input type="text"[formControl]="planForm.get('measurement_targetRating')" class="accomplish-input goal-text-area mt-1">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="planForm.get('selectedAccomplishment')?.value">
        <mat-checkbox [formControl]="planForm.get('managerApproval')" class="body fw-500 black">
          Submit to Manager for Approval
        </mat-checkbox>
      </div>
    </form>
  </div>
  <div class="d-flex align-items-center justify-content-between py-3 px-20 action-btn-con">
    <button mat-stroked-button class="btn secondary-button" (click)="dismiss()">
      <span>DISMISS</span>
    </button>
    <button mat-stroked-button class="btn primary-button" (click)="addNewGoal()">
      <span>{{editGoalObj ? 'EDIT' : 'ADD'}} NEW GOAL</span>
    </button>
  </div>
</div>

<ng-template #tableTemplate let-dataSource="dataSource" let-title="title" let-hasBadge="hasBadge" let-displayedColumns="displayedColumns">
  <div class="table-container mb-20">
    <table mat-table [dataSource]="dataSource" class="demo-table w-100 mt-4 mb-20">
      <ng-container matColumnDef="skill">
        <th mat-header-cell *matHeaderCellDef>{{ title | uppercase }} SKILLS (2/4)</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>electric_bolt</mat-icon>
          {{ element.skillName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="current">
        <th mat-header-cell *matHeaderCellDef>CURRENT</th>
        <td mat-cell *matCellDef="let element">{{ element.currentRating }}</td>
      </ng-container>
      <ng-container matColumnDef="expected">
        <th mat-header-cell *matHeaderCellDef>EXPECTED</th>
        <td mat-cell *matCellDef="let element">{{ element.expectedRating }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <ng-container *ngIf="!hasBadge">
        <tr
        mat-row
        (click)="clickedRows.add(row)"
        [class.demo-row-is-clicked]="clickedRows.has(row)"
        *matRowDef="let row; columns: displayedColumns;"
      ></tr>
      </ng-container>
    </table>
  </div>
  <div class="table-container" *ngIf="hasBadge">
    <div class="badge-container mb-20">
      <div class="badge item-center">{{dynamicBadgeText}}</div>
      <table mat-table [dataSource]="dataSource" class="demo-table w-100 mb-1">
        <ng-container matColumnDef="skill">
          <td mat-cell *matCellDef="let element">
            <mat-icon>electric_bolt</mat-icon>
            {{ element.skillName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="current">
          <td mat-cell *matCellDef="let element">{{ element.currentRating }}</td>
        </ng-container>
        <ng-container matColumnDef="expected">
          <td mat-cell *matCellDef="let element">{{ element.expectedRating }}</td>
        </ng-container>
        <tr
        mat-row
        (click)="clickedRows.add(row)"
        [class.demo-row-is-clicked]="clickedRows.has(row)"
        *matRowDef="let row; columns: displayedColumns;"
       ></tr>
      </table>
    </div>
  </div>
</ng-template>

