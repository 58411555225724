export enum STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED',
    COMPLETED = 'COMPLETED'
}

export enum TYPE {
    USER = 'USER',
    STATUS = 'STATUS',
    EXIT = 'EXIT',
    PERCENT = 'PERCENT'
}

export enum TREATMENT_MAP {
  MY_LEARNINGS = 'myLearningElement',
  V1 = 'C',
  V2 = 'T1'
}

export interface ISpecialization {
    pk: string,
    title: string,
    category: string,
    progress: number,
    selected: boolean,
    skills: ISkillList[]
}

export interface IProgressCircle {
    colors: {
        backgroundColor,
        innerStrokeColor,
        outerStrokeColor
    };
    percentage?: number
}
  
export interface ILearningElements {
    id: string,
    title: string,
    progressCircle: IProgressCircle,
    expectedProficiency: number,
    proficiency: number,
    color: string
}

export interface IProgressPath {
    title: string,
    subtitle?: string,
    data: [],
    progress?: string
}

export const IN_PROGRESS_COLORS = {
    innerStrokeColor: "#FFD8BA",
    outerStrokeColor: "#EB6300",
    backgroundColor: "#EB6300"
}

export const COMPLETED_COLORS = {
    innerStrokeColor: "#24A34D",
    outerStrokeColor: "#24A34D",
    backgroundColor: "#24A34D"
}
 
export const NOT_STARTED_COLORS = {
    innerStrokeColor: "#CFE3FD",
    outerStrokeColor: "#00A2ED",
    backgroundColor: "#00A2ED"
}

export enum SKILL_CATEGORY {
    MANDATORY = 'Mandatory',
    OPTIONAL = 'Optional',
    CONDITIONAL_MANDATORY = 'Conditional Mandatory'
}

export interface IPairingMap {
    string?: number
}

export interface ISpecializationResponse {
    data: ISpecialization[],
    next: number
}

export interface ISpecializationTabs {
    title: string,
    skillLists: ISkillList[] | number[],
    conditionalMandatory?: ISkillList[]
}

export interface ISkillList {
    pk: string,
    title: string,
    category?: string,
    userProgress?: IRating[],
    rating?: IRating[],
    pairing?: string,
    numMandatorySkillsForPairing?: string,
    userLevel: IRating,
    expectedLevel: IRating,
    colors: IColors,
    progress?: number,
    individualLearningPath?: boolean
}

export interface IFilter {
  key?: string;
  value?: string[];
  options?: IOptionItem[]
  title?: string;
  placeholder?: string;
}

export interface ILearningElementCardData {
  ok: boolean;
  data: ILearningElement[];
  previous: any;
  next: any;
  count: number;
}

export interface ILearningElement {
  leId: string;
  clientId: string;
  completionTimeline?: string;
  contentUrl: string;
  createdAt: string;
  description: string;
  duration?: number;
  originCreator?: string;
  pk: string;
  skills: ISkill[];
  source?: string;
  status: string;
  subType?: string;
  thumbnailUrl?: string;
  title: string;
  type: string;
  updatedAt: string;
  enabled: number;
  learningElementPk: string;
  userProgress: IUserProgress[];
  resultStatus: string;
  assignedBy: any;
  rating?: number;
  data?: any[];
  userStatus: string;
  specialization: string;
  progress: number;
  endDate: string;
  startDate: string;
  currentState: string;
  skillItemIds: string[];
}

export interface IMyLearningPageVisibilityAccess {
  showOverview: boolean;
  showBySpecialization: boolean;
  showIndividualLearning: boolean;
}

interface ISkill {
  id: string;
  rating: string;
}

interface IUserProgress {
  type: string;
  value: string;
}

interface IRating {
    type?: string,
    value: string | number,
    progress?: number,
    alias?: string
}

interface IColors {
    innerStrokeColor: string,
    outerStrokeColor: string,
    backgroundColor: string
}

interface IOptionItem {
  pk: string;
  title: string;
  rating?: number;
}

export interface IGroupedData {
  title: string;
  optionalGroupId: string;
  subtitle: string;
  data: ILearningElement[];
}